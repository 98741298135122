import {
  AppstoreOutlined,
  TranslationOutlined,
  LineChartOutlined,
  BookOutlined,
  UserAddOutlined,
  SettingOutlined,
  HomeOutlined,
} from "@ant-design/icons";

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
  route?: string;
}

const menuItems: MenuItem[] = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: <HomeOutlined />,
    route: "/",
  },
  {
    key: "clients",
    label: "Klientlar",
    icon: <LineChartOutlined />,
    route: "/clients",
  },
  {
    key: "vacancies",
    label: "Vakansiyalar",
    icon: <BookOutlined />,
    route: "/vacancies",
  },
  {
    key: "team",
    label: "Jamoa",
    icon: <UserAddOutlined />,
    route: "/team",
  },
  {
    key: "portfolio",
    label: "Portfolio",
    icon: <AppstoreOutlined />,
    route: "/portfolio",
  },
  {
    key: "settings",
    label: "Sozlamalar",
    icon: <SettingOutlined />,
    route: "/settings",
  },
  {
    key: "translations",
    label: "Tarjimalar",
    icon: <TranslationOutlined />,
    route: "/translations",
  },
];

function gen4() {
  return Math.random()
    .toString(16)
    .slice(-4);
}

export default {
  menuItems,
};

export { gen4 };
